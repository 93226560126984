
/* eslint-disable */
import CloudFun, { defineComponent, ref, onMounted, PropType } from '@cloudfun/core'
import FileUpload, { VueUploadItem } from 'vue-upload-component';

interface FileDto {
    Id: number;
    Name: string;
}

export default defineComponent({
    name: "PublicationFileUpload",
    components: {
        FileUpload,
    },
    props: {        
        canDelete: {
            type: Boolean,
            default: true
        },
        canUpload: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        promises: {
            type: Object as PropType<{
                query(): Promise<FileDto[]>;
                insert(data: FormData, type: any): Promise<FileDto[]>,
                delete(id: number): Promise<void>
            }>,
            required: true
        },
        validExt: {
            type: Array,
            default: () => ['.gif', '.jpg', '.jpeg', '.png', '.webp', '.zip', '.rar', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls', '.csv', '.txt', '.pdf']
        },
        validSize: { // Bytes
            type: Number,
            default: 25 * 1024 * 1024
        },
        title: String
    },
    setup(props) {
        const uploadFiles = ref<VueUploadItem[]>([]);
        const fileUpload = ref({});
        const type = ref(2);
        const data = ref<FileDto[]>();

        const refresh = async () => {
            try {
                data.value = await props.promises?.query()
                
                data.value.forEach(async (e: any) => {
                    if(e.Type == 3)
                        e.imgSrc = await displayImg(e.Id);
                })

            } catch (error: any) {
                data.value = []
                CloudFun.send("error", {
                    subject: "執行失敗",
                    content: error,
                });
            }

        }
        onMounted(() => {
            refresh();
        });

        const upload = () => {
            if (type.value !== 2 && type.value !== 3) {
                CloudFun.send('error', { subject: '附件上傳失敗', content: '請選擇上傳類型' })
                return;
            }
            if (uploadFiles.value.length === 0) return;
            const formData = new FormData();
            for (const file of uploadFiles.value) {
                if (!file.size || !file.name || !file.file) return;
                if (file.size >= props.validSize) {
                    CloudFun.send('error', { subject: '附件上傳失敗', content: '檔案大小不得超過25M' })
                    uploadFiles.value = [];
                    return;
                }

                const ext = '.' + file.name.split('.')?.[1];

                let validate =
                    type.value == 2 ? ['.gif', '.jpg', '.jpeg', '.png', '.webp', '.zip', '.rar', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls', '.csv', '.txt', '.pdf']
                    : [ '.jpg', '.jpeg', '.png', '.webp' ];
                if (validate && validate.indexOf(ext) === -1) {
                    uploadFiles.value = [];
                    CloudFun.send('error', { subject: '附件上傳失敗', content: '不支援此檔案類型' })
                    return;
                }
                formData.append("files", file.file);
            }
            props.promises?.insert(formData, type.value).then(
                () => {
                    CloudFun.send('info', { subject: '執行成功', content: '上傳完成' })
                    refresh();
                },
                failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
            ).finally(() => {
                uploadFiles.value = [];
            });
        }

        const s2ab = (s: any) => {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        const model = CloudFun.current?.model
        const download = async(id: number, name: string) => {
            //window.open(`${process.env.VUE_APP_BACKEND_URL}/api/files/download/${id}`)
            try {
                var data = await model!.dispatch('files/download', { key: id });

                const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = name;
                link.click();
            } catch (e: any) {
                CloudFun.send("error", {
                subject: "執行失敗",
                content: e,
                });
            }
        }

        const remove = (id: number, documentId: any) => {
            if(documentId == null) {
                CloudFun.send('error', { subject: '操作失敗！', content: '無法取得資料' });
                return;
            }
            props.promises?.delete(id).then(
                () => refresh(),
                failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
            );
        }
        
        const uploadEntity = ref<any>(null);

        const displayImg = async(id: any) => {
            if(id) {
                var data = await model!.dispatch('files/download', { key: id });
                //console.log('data:image/jpeg;base64,'+data);
                const blob = new Blob([s2ab(atob(data))], {type: 'text/plain'});
                return 'data:image/jpeg;base64,'+data;
            }
        }

        return {
            fileUpload,
            data,
            uploadFiles,
            refresh,
            upload,
            download,
            remove,
            uploadEntity,
            type,
            displayImg,
        }
    },
    methods: {
        getData() {
            return this.data;
        }
    }
})
